import { CommunicationLanguage, IInternalEmployee } from '@internal-models/employee.model';
import { IApiObject, IDataQuery, IListData } from '@shared-libs/interfaces';

import { Api } from '@shared-providers/api.provider';
import { IConsultantInitialisationImages } from '@internal-libs/interfaces';
import { ITalentTagOnEmployee } from '@shared-models/talent-tag.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@shared-services/loading.service';
import { Observable } from 'rxjs';
import { Provider } from '@shared-providers/Provider';
import { encodeQueryUrl } from '@shared-libs/helpers';

@Injectable({
	providedIn: 'root',
})
export class EmployeeProvider extends Provider<IInternalEmployee> {
	protected readonly url: string = '/employees';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public getAllSuggestedEmployeesForDigipoint(
		lat: number,
		long: number,
		distance: number
	): Observable<IApiObject<Array<IInternalEmployee>>> {
		return this.process(
			this.api.get(`${this.url}/digipoint/suggestion?lat=${lat}&long=${long}&distance=${distance}`)
		) as Observable<IApiObject<Array<IInternalEmployee>>>;
	}

	public getAllEmployeesForDigipoint(): Observable<IApiObject<Array<IInternalEmployee>>> {
		return this.process(this.api.get(`${this.url}/digipoint`)) as Observable<IApiObject<Array<IInternalEmployee>>>;
	}

	public getEmployees(_query: IDataQuery): Observable<IApiObject<IListData<IInternalEmployee>>> {
		return this.process(this.api.get(`${this.url}?query=${encodeQueryUrl(_query)}`), !_query.offset) as Observable<
			IApiObject<IListData<IInternalEmployee>>
		>;
	}

	public getEmployee(_id: string, _showLoading: boolean = true): Observable<IApiObject<IInternalEmployee>> {
		return this.process(this.api.get(`${this.url}/${_id}`), _showLoading) as Observable<IApiObject<any>>;
	}

	public getEmployeeGoogleSynced(): Observable<IApiObject<{ googleSynced: boolean }>> {
		return this.process(this.api.get(`${this.url}/google`), false) as Observable<IApiObject>;
	}

	public hasAppliedToADigipointEvent(employeeId: string): Observable<IApiObject<boolean>> {
		return this.process(this.api.get(`${this.url}/${employeeId}/digipoint-event`)) as Observable<IApiObject>;
	}

	public searchEmployees(_employee: {
		name: string;
		firstname: string;
	}): Observable<IApiObject<Array<IInternalEmployee>>> {
		return this.process(this.api.search(`${this.url}/search`, _employee), false) as Observable<
			IApiObject<Array<IInternalEmployee>>
		>;
	}

	public updateEmployee(_id: string, _employee: IInternalEmployee, showLoading = true): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${_id}`, _employee), showLoading);
	}

	public updateEmployeeCommunicationLanguage(
		_id: string,
		communicationLanguage: CommunicationLanguage
	): Observable<IApiObject<string>> {
		return this.process(
			this.api.put(`${this.url}/${_id}/communication-language`, { communicationLanguage })
		) as any as Observable<IApiObject<string>>;
	}

	public updateEmployeeNote(_id: string, note: string): Observable<IApiObject<string>> {
		return this.process(this.api.put(`${this.url}/${_id}/note`, { note })) as any as Observable<IApiObject<string>>;
	}

	public updateEmployeejobOffersConsent(_id: string, jobOffersConsent: boolean): Observable<IApiObject<string>> {
		return this.process(
			this.api.put(`${this.url}/${_id}/initialize/job-offers`, { jobOffersConsent })
		) as any as Observable<IApiObject<string>>;
	}

	public updateProfilePicture(_id: string, _base64image: string): Observable<IApiObject<string>> {
		return this.process(
			this.api.put(`${this.url}/${_id}/profile-picture`, { picture: _base64image })
		) as any as Observable<IApiObject<string>>;
	}

	public updateEmployeeTalentTags(_id: string, _talentTags: Array<ITalentTagOnEmployee>): Observable<IApiObject> {
		return this.process<ITalentTagOnEmployee[]>(this.api.put(`${this.url}/${_id}/talent-tags`, _talentTags), false);
	}

	public updatePayrollingIdCard(
		_id: string,
		details: Partial<
			Pick<IInternalEmployee, 'passportValidUntil'> & { passportFrontImage: string; passportBackImage: string }
		>
	): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${_id}/id-card`, details));
	}

	public updatePayrollingStudentCard(
		_id: string,
		details: Partial<
			Pick<IInternalEmployee, 'studentCardValidUntil'> & {
				studentCardFrontImage: string;
				studentCardBackImage: string;
			}
		>
	): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${_id}/student-card`, details));
	}

	public updateCards(
		_id: string,
		details: Partial<
			Pick<IInternalEmployee, 'studentCardValidUntil' | 'passportValidUntil'> & {
				studentCardFrontImage: string;
				studentCardBackImage: string;
				passportFrontImage: string;
				passportBackImage: string;
			}
		>
	): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${_id}/cards`, details));
	}

	public initializeConsultant(
		_images: IConsultantInitialisationImages,
		_employee: IInternalEmployee
	): Observable<IApiObject> {
		return this.process(
			this.api.put(`${this.url}/${_employee.id}/initialize`, { ..._employee, pictures: _images }),
			true
		);
	}

	public finishConsultantInitialisation(employeeId: string): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${employeeId}/initialize/finish`, {}), true);
	}

	public acceptTermsAndConditions(_employeeId: string, _termsAndConditionsId: number): Observable<IApiObject> {
		return this.process(
			this.api.put(`${this.url}/${_employeeId}/acceptTermsAndConditions`, {
				termsAndConditionsId: _termsAndConditionsId,
			}),
			false
		);
	}

	public promoteConsultant(_employeeId: string): Observable<IApiObject<IInternalEmployee>> {
		return this.process(this.api.put(`${this.url}/${_employeeId}/promote`, {})) as Observable<IApiObject<any>>;
	}

	public sendPasswordReset(_employeeId: string): Observable<IApiObject<void>> {
		return this.process(this.api.put(`${this.url}/${_employeeId}/password-reset`, {})) as Observable<
			IApiObject<any>
		>;
	}

	public deactivateEmployee(_employeeId: string): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${_employeeId}/deactivate`, {})) as Observable<IApiObject<any>>;
	}

	public activateEmployee(_employeeId: string): Observable<IApiObject> {
		return this.process(this.api.put(`${this.url}/${_employeeId}/activate`, {})) as Observable<IApiObject<any>>;
	}
}
