import { IACL } from './interfaces';
import { ROLE } from './enums';
import { isEmpty } from 'lodash';

export const ACL: IACL = {
	client: {
		roles: [ROLE.CLIENT],
		routes: {
			opdrachten: ['*'],
			gegevens: ['*'],
		},
	},
	partner: {
		roles: [ROLE.SOCIAL_PARTNER, ROLE.PARTNER],
		routes: {
			dashboard: ['*'],
			'boek-opdracht': ['*'],
			opdrachten: ['*'],
			hulpmiddelen: ['*'],
			digipunten: ['*'],
			vormingen: ['*'],
			profielen: ['*'],
			gebruikers: ['*'],
			statistieken: ['*'],
		},
	},
	internal: {
		roles: [ROLE.CONSULTANT, ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
		routes: {
			profiel: ['*'],
			ratings: ['*'],
			kalender: ['*'],
			opdrachten: ['*'],
			tasks: [ROLE.SUPER_ADMIN],
			aanvragen: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			digipoints: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN, ROLE.CONSULTANT],
			digipoint: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			'boek-opdracht': [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			klanten: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			klant: ['*'],
			werknemers: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			werknemer: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			'consultant-metrics': [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			candidates: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			candidate: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			administratie: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			pakketten: [ROLE.ADMIN, ROLE.SUPER_ADMIN],
			'pakketten-templates': [ROLE.ADMIN, ROLE.SUPER_ADMIN],
			'pakketten-template': [ROLE.ADMIN, ROLE.SUPER_ADMIN],
			status: [ROLE.CONSULTANT],
			hulpmiddelen: ['*'],
			statistieken: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			payrolling: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			partner: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			partners: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			chats: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
			chat: [ROLE.CONSULTANT],
		},
	},
};

/**
 * Validate a route & user role against the ACL
 * @param _route The route the user want's to navigate to
 * @param _role The role of the user
 * @returns Whether the user is allowed or not
 */
export const validateACL = (_route: string, _role: ROLE): boolean => {
	const routes = _route.split('/').filter((route) => !isEmpty(route));
	if (routes.length > 1) {
		/**
		 * Validate if user role has access to parent route
		 */
		const acl = ACL[routes[0]];

		const allowedRoles = acl.roles;
		if (!allowedRoles.includes('*') && !allowedRoles.includes(_role)) {
			return false;
		}

		/**
		 * Validate if user role has access to actual (child) route
		 */
		const allowedRolesForChild = acl.routes[routes[1]];
		if (!allowedRolesForChild.includes('*') && !allowedRolesForChild.includes(_role)) {
			return false;
		}
	}
	return true;
};
